.cover{
  height: 70%;
  object-fit: cover;
  width: 100%;
}

.popover{
  margin-top: -9rem;
  min-width: 20rem;
  pointer-events: none;
}
.popover span{
  font-size: small;
}
.popover label{
  /* font-weight: bold; */
  font-size: small;
  width: 100%;
  /* margin-bottom: .5rem; */
}
.popover p{
  font-size: small;
}