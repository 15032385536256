
$odp-primary:   #44b168;
$odp-secondary: #903373;
$odp-info:      #da8;
$odp-red:       #dc3545;
$odp-yellow:    #ffc107;
$odp-cyan:      #0dcaf0;
$odp-olive:     #235218;

$theme-colors: (
  "primary":        $odp-primary,
  "secondary":      $odp-secondary,
  "info":           $odp-info,
  "success":        $odp-cyan,
  "warning":        $odp-yellow,
  "danger":         $odp-red,
  "olive":          $odp-olive
  // "light":      $light,
  // "dark":       $dark
);

// ro-cols to grid system
// $enable-grid-classes: false;
// $enable-cssgrid: true;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
// $body-bg: #44b168;
// $background-image: var(--bs-gradient);

html{
   font-size: 14px; // deafault browser
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'OpenSans-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


a, a:link, a:visited, a:active {
  text-decoration: unset;
}

a, a:link, a:visited, a:active {
  color: map-get($theme-colors,"secondary")
}

a:hover {
  color: $white;
}

.nav-link,
.nav-link:visited,
.nav-link:active  {
  color: map-get($theme-colors,"secondary")
}
.nav-link:hover{
  color: $white
}

.navbar-nav .nav-link:hover{
  color: map-get($theme-colors,"primary")
}

footer .nav-link,
footer .nav-link:visited,
footer .nav-link:active  {
  color: $white;
}
footer .nav-link:hover{
  color: map-get($theme-colors,"warning")
}

.App {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), map-get($theme-colors,"primary"));
}

header {
  font-size: calc(10px + 1vmin);
}

main {
  min-height: 100vh;
}

footer {
  color: $white;
  background-color:$green-500;
  text-align: center;
}

.footer-copyright {
  color: $white;
  background-color: $green-800;
  font-weight: bold;
  font-size: small;
}
.footer-copyright .nav-link, .footer-copyright a{
  color:$white;
} 


.cover-fit{
  height: 70%;
  object-fit: cover;
  width: 100%;
}

.mailto {
  color:  map-get($theme-colors,"secondary")!important;
}

.btn-whatsapp{
  border: 0; 
  background: transparent; 
  color: map-get($theme-colors,"secondary")
}